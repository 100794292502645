<template>
    <BaseContainer hideMenu="true">
        <span slot="principal">
            <v-container fluid style="background-color: #485461;
                background-image: linear-gradient(315deg, #485461 0%, #28313b 74%);">
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="elevation-12">
                            <v-toolbar dark>
                                <v-spacer />
                                <v-toolbar-title>Zaper Control</v-toolbar-title>
                                <v-spacer />
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        label="E-mail"
                                        ref="login"
                                        prepend-icon="person"
                                        type="text"
                                        v-model="email"
                                        autocomplete="username"
                                        @keypress.enter="$refs.password.focus()"
                                    />

                                    <v-text-field
                                        label="Senha"
                                        ref="password"
                                        v-model="password"
                                        prepend-icon="lock"
                                        @keypress.enter="login"
                                        autocomplete="current-password"
                                        :type="showPassword ? 'text' : 'password'"
                                        @click:append="showPassword = !showPassword"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    />
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    block
                                    @click="login"
                                    color="#000"
                                    style="border: 1px solid #fff;"
                                    :loading="loading"
                                    class="white--text"
                                >Entrar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import { mapMutations } from 'vuex';

export default {
    components: { BaseContainer },

    name: 'Login',

    data: () => ({
        email: '',
        password: '',
        loading: false,
        showPassword: false,
    }),

    methods: {
        ...mapMutations({
            setUsuario: 'setUsuario',
        }),

        login() {
            if (!this.email) {
                alert('Informe o e-mail', 'warning');
                return;
            }
            if (!this.password) {
                alert('Informe a senha', 'warning');
                return;
            }

            this.loading = true;
            this.$http.post('login-control', {
                email: this.email,
                password: this.password
            }).then(resp => {
                const data = resp.data;

                if (data.token) {
                    this.makeLogin(data);
                    return;
                }

                alert('Usuário ou senha inválido', 'warning');
            })
            .catch(() => (alert('Não foi possivel realizar login', 'warning')))
            .finally(() => (this.loading = false))
        },

        makeLogin(data) {
            this.setUsuario(data);
            sessionStorage.setItem('token', data.token);

            this.$http.defaults.headers.common = {
                Authorization: `Bearer ${data.token}`,
            };

            this.$router.push('/');
        },
    }
}
</script>

<style scoped>
.container  {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.container .row {
    margin: 0 auto;
}
</style>
